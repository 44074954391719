.bills-table {
  margin-top: 20px;
  border: 1px solid #e1e1e1;
  width: 100%;
}

.bills-table thead {
  background-color: rgba(114, 116, 120, 0.08);
  font-weight: 400;
  font-size: 14px;
  width: 100%;
}
.bills-table tfoot {
  background-color: rgba(114, 116, 120, 0.08);
  font-weight: 400;
  font-size: 16px;
}

.bills-table td {
  border-right-style: none !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  /* border: none !important; */
}

.bills-table thead td,
.bills-table td {
  padding: 12px 8px;
}

.bills-table tbody {
  font-size: 14px;
  font-weight: 400;
}
.bills-table tbody td {
  padding: 12px 8px;
}
.bills-table tfoot td {
  font-size: 18px;
}

.bill-summary {
  margin-top: 20px;
  border: 1px solid rgba(114, 116, 120, 0.08);
  padding: 10px;
}

.bill-summary td,
.bill-summary th {
  padding: 16px 8px;
  text-align: center;
}

.bill-summary th {
  color: #36383c;
  font-weight: 400;
}

.bill-summary td:first-of-type {
  text-align: left;
}

.bill-summary td:nth-child(2) {
  text-align: center;
}
.bill-summary td:last-of-type {
  text-align: right;
}

.bill-summary th:first-of-type {
  text-align: left;
}

.bill-summary th:nth-child(2) {
  text-align: center;
}
.bill-summary th:last-of-type {
  text-align: right;
}

/* Hide arrows in Chrome, Edge, and Safari */
.bill-item-discount::-webkit-inner-spin-button,
.bill-item-discount::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
.bill-item-discount {
  -moz-appearance: textfield;
}

.bill-item-discount {
  width: 40px;
  padding: 6px;
  border-radius: 4px !important;
  box-shadow: none !important;
  border: 1px solid #e1e1e1 !important;
  text-align: center !important;
  background-color: white !important;
}
.bill-item-discount:disabled {
  background-color: #e1e1e1 !important;
}

.epq-pdf-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.epq-pdf-header h3 {
  font-size: 48px;
}

.epq-large-header-text {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.epq-large-header-text h3 {
  font-size: 18px;
  font-weight: 800;
}
.epq-large-header-text p {
  font-size: 14px;
  font-weight: 400;
  margin-top: -15px;
  color: #727478;
}

.work-section-pdf {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
}

.work-content-epq {
  width: 50%;
  display: flex;
  justify-content: space-between;
  /* align-items: s; */
}

.work-title-epq {
  background-color: #f8f9f9;
  font-weight: normal;
  width: 50%;
  padding: 16px 8px;
  border: 0.5px solid #e1e1e1;
  /* min-height: 60px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.work-data-epq {
  font-weight: bold;
  width: 50%;
  padding: 16px 8px;
  border: 0.5px solid #e1e1e1;
  min-height: 60px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.work-data-error{
 border: 1px solid red !important;
 background-color: rgba(255, 0, 0, 0.1) !important;
}
.work-data-error:focus, .work-data-error:hover{
  border: 1px solid red !important;
  background-color: rgba(255, 0, 0, 0.1) !important;
  outline: 1px solid red !important;
}

.epq-signature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 10px;
}
.epq-signature-item {
  width: 25%;
}
.epq-signature-item > p {
  margin-bottom: 120px;
}
.epq-signature-item div {
  border-top: 1px solid #e1e1e1;
  padding-top: 15px;
}
.epq-signature-item div p {
  margin-bottom: 7px !important;
}

@media (max-width: 1350px) {
  .wo-filters-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.ebq-buttons-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px;
}
.ebq-buttons-header button {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.ebq-buttons-header button:hover {
  background-color: #e1e1e1;
}

.ebq-footer-button-container {
  background-color: #f8f9f9;
  box-shadow: 5px -2px 8px 0px rgba(145, 158, 171, 0.25);
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  width: calc(100%);
  z-index: 100;
}
.ebq-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
}
.ebq-footer-content div {
  width: 100px;
}
.ebq-footer-content button {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ebq-footer-content button:hover {
  background-color: #e1e1e1;
}

.bill-no-items-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #f5f7fa, #e3e6e9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bill-no-items-content {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
}

.bill-no-items-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
}

.bill-no-items-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: rgba(217, 49, 74, 0.7);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background 0.3s ease;
}

.bill-no-items-button:hover {
  background: var(--primary-color);
}

.bill-no-items-button:focus {
  outline: none;
  box-shadow: 0 0 5px var(--primary-color);
}

.disable-calss:disabled {
  background-color: #e1e1e1 !important;
  color: #36383c !important;
}

/* @media print {
  thead {
    display: table-row-group;
  }

  tfoot {
    display: table-row-group;
  }

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid; 
  }
} */

.print-component {
  /* display: none; */

  width: 100%;
  @media print {
    display: table !important;
  }
}

@media print {
  /* thead {
    display: table-row-group;
  } */
  .print-wrapper {
    display: block !important;
  }
  thead {
    margin-bottom: 20px;
  }
  tfoot {
    display: table-row-group;
  }

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
  }

  .epq-draft-watermark {
    position: fixed;
    top: 50%;
    left: 50%;
    font-size: 200px;
    transform: translate(-50%, -50%) rotate(-45deg);
    opacity: 0.1;
    z-index: -1;
  }
}
